//const BASE_SERVICE_URL = 'https://api-test.estorelogistics.com.au'; //"https://qa-api-apigw-esl.developer.azure-api.net"
const BASE_SERVICE_URL = 'https://prod-api-apigw-esl.azure-api.net';

export const SettingConstant = {
  // QA
  /*GA_MEASUREMENT_ID: 'G-PBT96QFNZ9',
  SUBCRIPTION_KEY: 'bdf30cdd0b094439bd61c3c503eda3f3',
  CLIENT_ID: '19eff4eb-c805-4b5b-a535-1fce9a1aef2c', //'239c87ca-d77b-47a7-9644-cee60c551e2d',
  CLIENT_SECRET: 'wnA8Q~8qRpVJSw2LHQiAkPtZ-sZ~euqmb.Qh0cVM', //'M_v8Q~a_sPZai~o46c~R.Fe1GHxahcoA7uylBaXU',
*/
  // PROD
  GA_MEASUREMENT_ID: 'G-NTZ9KKHDGE',
  SUBCRIPTION_KEY: '3caa843846854da4badd0a22f8e8d106',
  CLIENT_ID: '5c7f7570-ef62-498e-a77c-fc07b7c78938',
  CLIENT_SECRET: 'A4W8Q~6TnMo5F7_YTTNkeD4UByb0EykClZEGpb2J',

  TENANT: 'estorelogisticsb2c',
  POLICY: 'B2C_1_API_SignIn',
  CODE_VERIFIER: 'CODE_VERIFIER',
  TOKEN_DATA: 'TOKEN_DATA',

  UAM_SERVICE_URL: `${BASE_SERVICE_URL}/uam/v1/`,
  APP_SERVICE_URL: `${BASE_SERVICE_URL}/api/v1/`,
};
